import React, { useEffect } from "react";
import Header from "../components/header/header";
import ReadMore from "../components/readMore/readMore.js";
import Histogram from "../assets/img/histogram.png";
import Heart from "../assets/img/heart.png";
import Smile from "../assets/img/smile.png";

import "../assets/styles/pages/_why-eucleia.scss";

const PageProps = {
  title: "Perché Eucleia",
  tagline: "Perché Eucleia",
  metaDescription: "",
  banner: "balloons.png",
  alt: "Logo Eucleia",
  to: "/",
  list: [
    "Trasformare l’esperienza del cliente",
    "Ricevere recensioni positive",
    "Fidelizzare il cliente",
    "Migliorare il posizionamento e il ranking online",
    "Aumentare i tuoi guadagni",
  ],
};

const WhyEucleia = () => {
  
  useEffect(() => {

    // Histogram Animation
    const histogramImg = document.querySelector(".histogramImg");
    histogramImg.classList.remove("activeAnim");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          histogramImg.classList.add("activeAnim");
          return;
        }

        histogramImg.classList.remove("activeAnim");
      });
    });

    observer.observe(document.querySelector(".histogram"));
  }, []);

  return (
    <>
      <Header {...PageProps} />
      <div className="whyEucleia">
        <div className="section" />
        <div className="columns">
          <div className="column is-offset-3 is-6">
            <div className="container">
              <h2>
                Eucleia ti offre l'opportunità di scalare le classifiche di
                gradimento dei portali
              </h2>
            </div>
          </div>
        </div>
        <div className="section" />
        <div className="container">
          <div className="columns">
            <div className="column">
              <br />
              <br />
              <h2>Per essere scelti più facilmente</h2>
              <br />
              <p>
                I primi classificati hanno mediamente un numero di prenotazioni
                più alto rispetto agli altri.
              </p>
            </div>
            <div className="column">
              <div className="container histogram">
                <img
                  className="histogramImg activeAnim"
                  src={Histogram}
                  alt="Histogram"
                />
                <img src={Heart} alt="Heart" />
                <img src={Smile} alt="Smile" />
              </div>
            </div>
            <div className="column">
              <br />
              <br />
              <h2>
                E non competere
                <br /> sul prezzo
              </h2>
              <br />
              <p>
                Puoi incrementare il costo a cui vendere le camere online o su
                prenotazione.
              </p>
            </div>
          </div>
        </div>
        <div className="section" />
        <div className="container howCan">
          <h2>Come puoi farlo</h2>
          <div className="section" />
          <div className="columns is-centered">
            <div className="column">
              <h4>Comunicando</h4>
              <br />
              <p>
                Con il 100% dei tuoi clienti in maniera efficace, riducendo al
                minimo l’intervento umano.
              </p>
            </div>
            <div className="column">
              <h4>Influenzando</h4>
              <br />
              <p>
                Il giudizio del cliente intercettando le problematiche e
                riducendo le recensioni negative.
              </p>
            </div>
          </div>
          <div className="section" />
          <div className="columns is-centered">
            <div className="column">
              <h4>Personalizzando</h4>
              <br />
              <p>L’esperienza dei tuoi ospiti secondo i loro veri bisogni.</p>
            </div>
            <div className="column">
              <h4>Aumentando</h4>
              <br />
              <p>Le recensioni positive e le prenotazioni.</p>
            </div>
          </div>
          <div className="section" />
          <div className="columns is-centered">
            <div className="column">
              <h4>Automatizzando</h4>
              <br />
              <p>Il controllo delle recensioni.</p>
            </div>
            <div className="column">
              <h4>Scoprendo</h4>
              <br />
              <p>
                La percezione dei tuoi ospiti in tempo reale e se un cliente ha
                riscontrato un problema.
              </p>
            </div>
          </div>
        </div>
        <div className="section" />
        <ReadMore to="/functionalities" text="Scopri tutte le funzionalità" color="white" />
        <div className="section" />
      </div>
    </>
  );
};

export default WhyEucleia;
